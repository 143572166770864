.error-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.error-h1{
    font-size: 3rem;
    padding: .7rem;
    letter-spacing: 1rem;
}
.error-h2{
    font-size: 2rem;
    font-size: 2rem;
    letter-spacing: 1.4rem;
}
.error-absolute{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
}
/* Media Queries */
@media(min-width:1600px){
    .error-h1{
        font-size: 6rem!important;
        padding: 3rem!important;
      }
    .error-h2{
        font-size: 4rem!important;
        letter-spacing: .5rem!important;
    }
}
@media(max-width:1440px){
    .error-h1{
        font-size: 3rem;
        padding: 2rem;
      }
    .error-h2{
        font-size: 2rem;
        letter-spacing: .5rem;
    }
}
@media(max-width:1024px){
    .error-h1{
        font-size: 3rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:768px){
    .error-h1{
        font-size: 3rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 2rem;
        letter-spacing: .4rem;
    }
}
@media (max-width:425px){
    .error-h1{
        font-size: 2.5rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.5rem;
        letter-spacing: .4rem;
    }
}
@media (max-width:375px){
    .error-h1{
        font-size: 2rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.3rem;
        letter-spacing: .3rem;
    }
}
@media (max-width:320px){
    .error-h1{
        font-size: 2rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.2rem;
        letter-spacing: .3rem;
    }
}