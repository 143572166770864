.about-section {
    height: 80vh;
    width: 100%;
}

.about-container {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    height: 100%;
    grid-gap: 0;
    margin: 0 auto;
    padding: 0 4rem;
}

.about-title1 {
    grid-column: 1/6;
    grid-row: 1/2;
    font-size: 8rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    font-weight: bolder;
    display: flex;
    justify-content: left;
    align-self: flex-end;
    opacity: 0;
    visibility: hidden;
    transform: translateY(+6rem);
}

.about-title2 {
    grid-column: 1/6;
    grid-row: 2/3;
    font-size: 8rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    font-weight: bolder;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    transform: translateY(+7rem);
}

.about-subtitle {
    grid-column: 1/5;
    grid-row: 3/4;
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    display: flex;
    justify-content: left;
    align-self: flex-start;
    opacity: 0;
    visibility: hidden;
    transform: translateY(+7rem);
}

.about-text {
    grid-column: 1/6;
    grid-row: 4/-1;
    font-size: 1.8rem;
    margin-top: -3rem;
    display: block;
    justify-content: center;
    align-items: flex-start;
    letter-spacing: .25rem;
    opacity: 0;
    visibility: hidden;
    transform: translateY(+7rem);
}

.about-media {
    opacity: 0;
    visibility: hidden;
    transform: translateY(+7rem);
}

.about-media * {
    padding:7rem 1rem 0 0;
    display: inline-flex;
    justify-content: left;
    font-size: 1.6rem;
    font-weight: bold;
}

/****************** skills */
.about-master-container {
    grid-column: 7/-1;
    grid-row: 2/10;
    display: block;
}

.about-skills-title {
    grid-column: 7/-1;
    grid-row: 3/4;
    margin-bottom: 2rem;
    letter-spacing: .2rem;
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
    color: #b9b9b9;
    opacity: 0;
    visibility: hidden;
    transform: translateX(+2rem);
}

.about-skills-container {
    grid-column: 7/-1;
    grid-row: 2/-1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
}

.about-skills-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.about-skills-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 100%;
}

.about-skills-column li {
    display: flex;
    justify-content: center;
    padding: 1.9rem;
    font-size: 1.6rem;
}

.media-about {
    padding: 0 .4rem;
}

.about-skills-column li:hover {
    color: rgb(255, 255, 255);
}

.github-about {
    transition: 1s ease-out;
}

.github-about:hover {
    cursor: pointer;
    color: rgb(243, 243, 243);
    transition: 1s ease-out;
}

.linkedin-about {
    transition: 1s ease-out;
}

.linkedin-about:hover {
    cursor: pointer;
    color: rgb(243, 243, 243);
    transition: 1s ease-out;
}

/* Media Queries */
@media(min-width:1600px) {
    .about-section {
        height: 100% !important;
        width: 100% !important;
    }

    .about-title1 {
        font-size: 12rem !important;
    }

    .about-title2 {
        font-size: 12rem !important;
    }

    .about-subtitle {
        padding: 0rem 0rem;
        font-size: 7.3rem !important;
        display: flex !important;
        justify-content: left !important;
        align-items: flex-start !important;
    }

    .about-text {
        padding: 0rem 0rem;
        font-size: 2.8rem !important;
    }

    .about-media * {
        font-size: 2.2rem !important;
    }

    .about-skills-title {
        font-size: 3.3rem !important;
        padding: 2.8rem !important;
    }

    .about-skills-column li {
        font-size: 2.5rem !important;
        padding: 2.5rem 0rem !important;
    }
}

@media(min-width:1440px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 9rem;
    }

    .about-title2 {
        font-size: 9rem;
    }

    .about-subtitle {
        display: flex;
        font-size: 5rem;
    }

    .about-text {
        font-size: 2.3rem;
    }

    .about-media * {

        font-size: 2rem;
    }

    .about-skills-title {
        font-size: 3.1rem;
    }

    .about-skills-column li {
        font-size: 2.4rem;
        padding: 2.5rem 0rem;
    }
}

@media(max-width:1024px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-container {
        display: flex;
        flex-direction: column;
        padding: 0rem 8rem;
    }

    .about-title1 {
        display: contents;
        justify-content: left;
        font-size: 9rem;
    }

    .about-title2 {
        padding: 0rem 0;
        font-size: 9rem;
    }

    .about-subtitle {
        padding: 3rem 0;
        font-size: 5rem;
    }

    .about-text {
        padding: 3rem 0;
        font-size: 3rem;
    }

    .about-media * {
        padding: 3.2rem 1rem 0 0;
        font-size: 2.6rem;
    }

    .about-skills-title {
        margin: 2rem 0;
        font-size: 3.5rem;
    }

    .about-skills-column li {
        font-size: 2.4rem;
        padding: 2.5rem;
    }
}

@media(max-width:768px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 8rem;
    }

    .about-title2 {
        font-size: 8rem;
    }

    .about-subtitle {
        font-size: 4rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 3rem;
    }

    .about-media * {
        padding: 3rem .5rem 0 0;
        font-size: 2.2rem;
    }

    .about-skills-title {
        margin: 2rem 0;
        font-size: 3rem;
    }

    .about-skills-column li {
        font-size: 2.5rem;
        padding: 2.5rem;
    }
}

@media(max-width:425px) {
    .about-container {
        display: flex;
        flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 7rem;
    }

    .about-title2 {
        font-size: 7rem;
    }

    .about-subtitle {
        font-size: 3rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 2rem;
    }

    .about-media * {
        padding: 2rem .3rem 0 0;
        font-size: 1.4rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.8rem;
    }

    .about-skills-column li {
        font-size: 1.6rem;
        padding: 2rem .3rem;
    }
}

@media(max-width:375px) {
    .about-container {
        display: flex;
        flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 5rem;
    }

    .about-title2 {
        font-size: 5rem;
    }

    .about-subtitle {
        font-size: 3rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 2rem;
    }

    .about-media * {
        padding: 2rem .4rem 0 0;
        font-size: 1.2rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.6rem;
    }

    .about-skills-column li {
        font-size: 1.4rem;
        padding: 2rem .3rem;
    }
}

@media(max-width:320px) {
    .about-container {
        display: flex;
        flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 4rem;
    }

    .about-title2 {
        font-size: 4rem;
    }

    .about-subtitle {
        font-size: 2.2rem;
    }

    .about-text {
        padding: 3rem 0;
        font-size: 1.8rem;
    }

    .about-media * {
        padding: 1rem .2rem 0 0;
        font-size: 1.1rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.8rem;
    }

    .about-skills-column li {
        font-size: 1.2rem;
        padding: 2rem .2rem;
    }
}