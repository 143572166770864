.accordion-section {
  height: 80vh;
  width: 100%;
  position: relative;
}

.accordion-title {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  top: 6rem;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(+2rem);
}

.accordion-wrap {
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 3rem;
  top: 12.3rem;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(+10rem);
  height: 50vh;
}

.item {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: flex 0.9s ease;
  margin: .7rem;
  height: 100%;
  position: relative;
  transition: all 1s ease-out;
  filter: grayscale(90%);
}

.item:hover {
  flex: 4;
  transition: all 1s ease-out;
  height: 140%;
  filter: none;
  cursor: pointer;
}

.item--image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item--describe {
  position: absolute;
  padding: .2rem;
  border-radius: .5rem;
  top: 93%;
  left: 7%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(43, 43, 43, 0.8);
  color: rgb(221, 221, 221);
}

.item:hover .item--describe {
  visibility: visible;
  opacity: 1;
  transition: all 1s ease-out;
}

.fa-arrow-right {
  background-color: rgba(43, 43, 43, 0.8);
  color: rgb(221, 221, 221);
  font-size: 1.4rem;
  margin-right: .2rem;
}

.item--describe:hover {
  color: rgb(129, 129, 129);
  transition: all 1s ease-out;
  cursor: pointer;
}

/* Media Queries */
@media(min-width:1600px) {
  .accordion-title {
    font-size: 3.4rem !important;
    top: 5rem !important;
  }

  .accordion-wrap {
    top: 12rem !important;
    height: 50vh !important;
  }

  .item--describe {
    left: 11% !important;
    top: 95% !important;
    font-size: 2.8rem !important;
  }

  .fa-arrow-right {
    font-size: 2.5rem !important;
  }
}

@media(min-width:1440px) {
  .accordion-title {
    font-size: 2rem;
    top: 5rem;
  }

  .accordion-wrap {
    top: 12rem;
    height: 55vh;
  }

  .item--describe {
    left: 8%;
    top: 90%;
    font-size: 2rem;
  }

  .fa-arrow-right {
    font-size: 1.5rem;
  }
}

@media(max-width:1024px) {
  .accordion-title {
    font-size: 1.7rem;
    top: 5rem;
  }

  .accordion-wrap {
    top: 12rem;
    height: 50vh;
  }

  .item--describe {
    left: 10%;
    top: 96%;
    font-size: 1.6rem;
  }

  .fa-arrow-right {
    font-size: 1.4rem;
  }
}

@media(max-width:768px) {
  .accordion-title {
    font-size: 1.7rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 7rem;
    height: 50vh;
  }

  .item--describe {
    left: 10%;
    top: 95%;
    font-size: 1.2rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:425px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 5rem;
    height: 60vh;
  }

  .item--describe {
    left: 21%;
    top: 91%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:375px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 6rem;
    height: 55vh;
  }

  .item--describe {
    left: 24%;
    top: 96%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:320px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 6rem;
    height: 53vh;
  }

  .item--describe {
    left: 29%;
    top: 89%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}