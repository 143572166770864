@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-size: 62.5%;
  scroll-behavior: smooth;  
  font-family: 'Raleway', sans-serif;
  background-color: #131313;
  color:#b4bdb2;
}
