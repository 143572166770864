.navbar-error{
    display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 2rem 4rem;
	width: 100%;
    z-index: 3;
}
.nav-error-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: center;
	justify-content: center;
}
.nav-error-link{
    font-size: 1.5rem;
	margin-left: 5rem;
    letter-spacing: .18rem;
	transition: 0.7s;
}
.nav-error-link:hover{
    color: rgb(245, 245, 245);
    cursor: pointer;
	transition: 0.5s;
	transform: scale(1.02);
	text-decoration: underline;
}
.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}
@media(min-width:1600px){
    .navbar-error{
		padding: 4rem 4rem!important;
	}
	.nav-error-link{
		font-size: 3rem!important;
	}
}
@media (max-width:768px){
	.navbar-error {
		position: relative;
	}
	.nav-error-links {
        position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
	}
	.nav-error-links.active {
        background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}
	.nav-error-item {
		padding: .5rem;
        border-bottom: 1px solid rgb(167, 167, 167);
	}
	.nav-icon {
		display: flex;
	}
}