.loader-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loader-h1{
    font-size: 3rem;
    padding: .7rem;
    letter-spacing: .9rem;
}
.loader-h2{
    font-size: 2rem;
    letter-spacing: 1rem;
}
.loader-absolute{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
/* Animation */
.fade-in-homeloader-title{
    animation: fade-in-homeloader-title .7s ease-in-out .7s both;
    -webkit-animation:fade-in-homeloader-title .7s ease-in-out .7s both;
}
@keyframes fade-in-homeloader-title {
    0% {opacity:0;}
    100% {opacity:1;}
}
@-webkit-keyframes fade-in-homeloader-title {
    0% {opacity:0;}
    100% {opacity:1;}
}
/* Media Queries */
@media(min-width:1600px){
    .loader-h1{
        font-size: 5rem!important;
        padding: 1rem!important;
        letter-spacing: 1.4rem!important;
    }
    .loader-h2{
        font-size: 3rem!important;
        letter-spacing: 2rem!important;
    }
}
@media (max-width:1440px){
    .loader-h1{
        font-size: 3rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.8rem;
        letter-spacing: .9rem;
    }
}
@media (max-width:1024px){
    .loader-h1{
        font-size: 3rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.8rem;
        letter-spacing: .9rem;
    }
}
@media (max-width:768px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .7rem;
    }
}
@media (max-width:425px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:375px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:320px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}
