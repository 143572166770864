@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-size: 62.5%;
  scroll-behavior: smooth;  
  font-family: 'Raleway', sans-serif;
  background-color: #131313;
  color:#b4bdb2;
}

.navbar {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	padding: 3rem 4rem;
	width: 100%;
}

.navbar-name {
	font-size: 2.5rem;
	letter-spacing: .3rem;
	text-transform: uppercase;
	font-weight: bold;
	justify-self: start;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	transition: 0.7s;
	font-weight: 800;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(+7rem);
	        transform: translateY(+7rem);
	color: rgb(207, 207, 207);
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: left;
	-webkit-justify-content: center;
	        justify-content: center;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(+7rem);
	        transform: translateY(+7rem);
}

.nav-link {
	font-size: 1.5rem;
	margin-left: 5rem;
	letter-spacing: .18rem;
	transition: 0.7s;
}

.nav-link:hover {
	color: rgb(245, 245, 245);
	cursor: pointer;
	transition: 0.5s;
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media(min-width:1600px) {
	.navbar {
		padding: 4rem 4rem !important;
	}

	.navbar-name {
		font-size: 6rem !important;
	}

	.nav-link {
		font-size: 3rem !important;
	}
}

@media(min-width:1440px) {
	.navbar-name {
		font-size: 2.5rem;
	}

	.nav-link {
		font-size: 1.8rem;
	}
}

@media (max-width:768px) {
	.navbar {
		position: relative;
	}

	.nav-links {
		position: absolute;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
		font-size: 1.2rem;
	}

	.nav-links.active {
		background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}

	.nav-item {
		padding: .5rem;
		border-bottom: 1px solid rgb(167, 167, 167);
	}

	.nav-icon {
		display: -webkit-flex;
		display: flex;
	}
}

@media(max-width:425px) {
	.navbar {
		padding: 2.5rem 4rem;
	}
}

@media(max-width:375px) {
	.navbar {
		padding: 2.5rem 4rem;
	}

	.navbar-name {
		font-size: 2.1rem;
	}

	.nav-item {
		padding: .1rem;
	}
}

@media(max-width:320px) {
	.navbar {
		padding: 2.5rem 4rem;
	}

	.navbar-name {
		font-size: 2.1rem;
	}

	.nav-item {
		padding: .1rem;
	}
}
.accordion-section {
  height: 80vh;
  width: 100%;
  position: relative;
}

.accordion-title {
  font-size: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  top: 6rem;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(+2rem);
          transform: translateY(+2rem);
}

.accordion-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  position: absolute;
  padding: 3rem;
  top: 12.3rem;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(+10rem);
          transform: translateY(+10rem);
  height: 50vh;
}

.item {
  -webkit-flex: 1 1;
          flex: 1 1;
  background-position: center;
  background-size: cover;
  background-repeat: none;
  transition: -webkit-flex 0.9s ease;
  transition: flex 0.9s ease;
  transition: flex 0.9s ease, -webkit-flex 0.9s ease;
  margin: .7rem;
  height: 100%;
  position: relative;
  transition: all 1s ease-out;
  -webkit-filter: grayscale(90%);
          filter: grayscale(90%);
}

.item:hover {
  -webkit-flex: 4 1;
          flex: 4 1;
  transition: all 1s ease-out;
  height: 140%;
  -webkit-filter: none;
          filter: none;
  cursor: pointer;
}

.item--image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.item--describe {
  position: absolute;
  padding: .2rem;
  border-radius: .5rem;
  top: 93%;
  left: 7%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.4rem;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(43, 43, 43, 0.8);
  color: rgb(221, 221, 221);
}

.item:hover .item--describe {
  visibility: visible;
  opacity: 1;
  transition: all 1s ease-out;
}

.fa-arrow-right {
  background-color: rgba(43, 43, 43, 0.8);
  color: rgb(221, 221, 221);
  font-size: 1.4rem;
  margin-right: .2rem;
}

.item--describe:hover {
  color: rgb(129, 129, 129);
  transition: all 1s ease-out;
  cursor: pointer;
}

/* Media Queries */
@media(min-width:1600px) {
  .accordion-title {
    font-size: 3.4rem !important;
    top: 5rem !important;
  }

  .accordion-wrap {
    top: 12rem !important;
    height: 50vh !important;
  }

  .item--describe {
    left: 11% !important;
    top: 95% !important;
    font-size: 2.8rem !important;
  }

  .fa-arrow-right {
    font-size: 2.5rem !important;
  }
}

@media(min-width:1440px) {
  .accordion-title {
    font-size: 2rem;
    top: 5rem;
  }

  .accordion-wrap {
    top: 12rem;
    height: 55vh;
  }

  .item--describe {
    left: 8%;
    top: 90%;
    font-size: 2rem;
  }

  .fa-arrow-right {
    font-size: 1.5rem;
  }
}

@media(max-width:1024px) {
  .accordion-title {
    font-size: 1.7rem;
    top: 5rem;
  }

  .accordion-wrap {
    top: 12rem;
    height: 50vh;
  }

  .item--describe {
    left: 10%;
    top: 96%;
    font-size: 1.6rem;
  }

  .fa-arrow-right {
    font-size: 1.4rem;
  }
}

@media(max-width:768px) {
  .accordion-title {
    font-size: 1.7rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 7rem;
    height: 50vh;
  }

  .item--describe {
    left: 10%;
    top: 95%;
    font-size: 1.2rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:425px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 5rem;
    height: 60vh;
  }

  .item--describe {
    left: 21%;
    top: 91%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:375px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 6rem;
    height: 55vh;
  }

  .item--describe {
    left: 24%;
    top: 96%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}

@media(max-width:320px) {
  .accordion-title {
    font-size: 1.5rem;
    top: 3rem;
  }

  .accordion-wrap {
    top: 6rem;
    height: 53vh;
  }

  .item--describe {
    left: 29%;
    top: 89%;
    font-size: 1rem;
  }

  .fa-arrow-right {
    font-size: 1rem;
  }
}
.main-footer{
    width: 100%;
    display: -webkit-flex;
    display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	padding: 2rem 4rem;
    opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(-7rem);
	        transform: translateY(-7rem);
}
.available-message{
    font-size: 2rem;
    text-align: start;
}
.social-media *{
    display: inline-block;
    -webkit-align-self: center;
            align-self: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 1.5rem;
}
.github-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.github-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
.behance-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.behance-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
.linkedin-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;

}
.linkedin-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
.email-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.email-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
.resume-media{
    transition: 1s ease-out;
}
.resume-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
@media(min-width:1600px){
    .main-footer{
        padding: 0rem 4rem 4rem 4rem!important;
    }
    .available-message{
        font-size: 4rem;
    }
    .social-media *{
        font-size: 2.8rem;
        margin-left: 1.7rem;
    }
}
@media(max-width:1440px){
    .main-footer{
        padding: 2rem 4rem 0rem 4rem;
    }
    .available-message{
        font-size: 2rem;
    }
    .social-media *{
        font-size: 1.4rem;
    }
}
@media(max-width:1024px){
    .main-footer{
        padding: 4rem 4rem;
    }
    .available-message{
        font-size: 1.8rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:768px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.8rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:425px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.4rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:375px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.3rem;
    }
    .social-media *{
        font-size: 1rem;
    }
}
@media(max-width:320px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.3rem;
    }
    .social-media *{
        font-size: .9rem;
    }
}
.email-media{
    transition: 1s ease-out;
}
.email-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: 1s ease-out;
}
.navbar-about {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	padding: 3.5rem 4rem 0rem 4rem;
	width: 100%;
	z-index: 3;

}

.nav-about-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
}

.nav-about-link {
	font-size: 1.5rem;
	margin-left: 5rem;
	letter-spacing: .18rem;
	transition: 0.7s;
}

.nav-about-link:hover {
	color: rgb(245, 245, 245);
	cursor: pointer;
	transition: 0.5s;
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media(min-width:1600px) {
	.navbar-about {
		padding: 5.3rem 4rem 0rem 4rem !important;
	}

	.nav-about-link {
		font-size: 3rem !important;
	}
}

@media(min-width:1440px) {
	.nav-about-link {
		font-size: 1.8rem;
	}
}

@media (max-width:768px) {
	.navbar-about {
		position: relative;
		top: 0rem;
		z-index: 33
	}

	.nav-about-links {
		position: absolute;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
	}

	.nav-about-links.active {
		background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}

	.nav-about-item {
		padding: .1rem;
		border-bottom: 1px solid rgb(167, 167, 167);
	}

	.nav-icon {
		display: -webkit-flex;
		display: flex;
		top: -1rem;
	}
}

@media(min-width:375px) {
	.nav-about-links {
		top: 3.5rem;
	}
}

@media(min-width:320px) {
	.nav-about-links {
		top: 3.6rem;
	}
}
.about-section {
    height: 80vh;
    width: 100%;
}

.about-container {
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    height: 100%;
    grid-gap: 0;
    margin: 0 auto;
    padding: 0 4rem;
}

.about-title1 {
    grid-column: 1/6;
    grid-row: 1/2;
    font-size: 8rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    font-weight: bolder;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    align-self: flex-end;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(+6rem);
            transform: translateY(+6rem);
}

.about-title2 {
    grid-column: 1/6;
    grid-row: 2/3;
    font-size: 8rem;
    letter-spacing: .5rem;
    text-transform: uppercase;
    font-weight: bolder;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(+7rem);
            transform: translateY(+7rem);
}

.about-subtitle {
    grid-column: 1/5;
    grid-row: 3/4;
    font-size: 5rem;
    font-weight: 600;
    letter-spacing: .5rem;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: left;
            justify-content: left;
    align-self: flex-start;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(+7rem);
            transform: translateY(+7rem);
}

.about-text {
    grid-column: 1/6;
    grid-row: 4/-1;
    font-size: 1.8rem;
    margin-top: -3rem;
    display: block;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    letter-spacing: .25rem;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(+7rem);
            transform: translateY(+7rem);
}

.about-media {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(+7rem);
            transform: translateY(+7rem);
}

.about-media * {
    padding:7rem 1rem 0 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: left;
            justify-content: left;
    font-size: 1.6rem;
    font-weight: bold;
}

/****************** skills */
.about-master-container {
    grid-column: 7/-1;
    grid-row: 2/10;
    display: block;
}

.about-skills-title {
    grid-column: 7/-1;
    grid-row: 3/4;
    margin-bottom: 2rem;
    letter-spacing: .2rem;
    font-size: 2rem;
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    align-self: center;
    text-align: center;
    color: #b9b9b9;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateX(+2rem);
            transform: translateX(+2rem);
}

.about-skills-container {
    grid-column: 7/-1;
    grid-row: 2/-1;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-2rem);
            transform: translateY(-2rem);
}

.about-skills-row {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
}

.about-skills-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
}

.about-skills-column li {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 1.9rem;
    font-size: 1.6rem;
}

.media-about {
    padding: 0 .4rem;
}

.about-skills-column li:hover {
    color: rgb(255, 255, 255);
}

.github-about {
    transition: 1s ease-out;
}

.github-about:hover {
    cursor: pointer;
    color: rgb(243, 243, 243);
    transition: 1s ease-out;
}

.linkedin-about {
    transition: 1s ease-out;
}

.linkedin-about:hover {
    cursor: pointer;
    color: rgb(243, 243, 243);
    transition: 1s ease-out;
}

/* Media Queries */
@media(min-width:1600px) {
    .about-section {
        height: 100% !important;
        width: 100% !important;
    }

    .about-title1 {
        font-size: 12rem !important;
    }

    .about-title2 {
        font-size: 12rem !important;
    }

    .about-subtitle {
        padding: 0rem 0rem;
        font-size: 7.3rem !important;
        display: -webkit-flex !important;
        display: flex !important;
        -webkit-justify-content: left !important;
                justify-content: left !important;
        -webkit-align-items: flex-start !important;
                align-items: flex-start !important;
    }

    .about-text {
        padding: 0rem 0rem;
        font-size: 2.8rem !important;
    }

    .about-media * {
        font-size: 2.2rem !important;
    }

    .about-skills-title {
        font-size: 3.3rem !important;
        padding: 2.8rem !important;
    }

    .about-skills-column li {
        font-size: 2.5rem !important;
        padding: 2.5rem 0rem !important;
    }
}

@media(min-width:1440px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 9rem;
    }

    .about-title2 {
        font-size: 9rem;
    }

    .about-subtitle {
        display: -webkit-flex;
        display: flex;
        font-size: 5rem;
    }

    .about-text {
        font-size: 2.3rem;
    }

    .about-media * {

        font-size: 2rem;
    }

    .about-skills-title {
        font-size: 3.1rem;
    }

    .about-skills-column li {
        font-size: 2.4rem;
        padding: 2.5rem 0rem;
    }
}

@media(max-width:1024px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0rem 8rem;
    }

    .about-title1 {
        display: contents;
        -webkit-justify-content: left;
                justify-content: left;
        font-size: 9rem;
    }

    .about-title2 {
        padding: 0rem 0;
        font-size: 9rem;
    }

    .about-subtitle {
        padding: 3rem 0;
        font-size: 5rem;
    }

    .about-text {
        padding: 3rem 0;
        font-size: 3rem;
    }

    .about-media * {
        padding: 3.2rem 1rem 0 0;
        font-size: 2.6rem;
    }

    .about-skills-title {
        margin: 2rem 0;
        font-size: 3.5rem;
    }

    .about-skills-column li {
        font-size: 2.4rem;
        padding: 2.5rem;
    }
}

@media(max-width:768px) {
    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 8rem;
    }

    .about-title2 {
        font-size: 8rem;
    }

    .about-subtitle {
        font-size: 4rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 3rem;
    }

    .about-media * {
        padding: 3rem .5rem 0 0;
        font-size: 2.2rem;
    }

    .about-skills-title {
        margin: 2rem 0;
        font-size: 3rem;
    }

    .about-skills-column li {
        font-size: 2.5rem;
        padding: 2.5rem;
    }
}

@media(max-width:425px) {
    .about-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 7rem;
    }

    .about-title2 {
        font-size: 7rem;
    }

    .about-subtitle {
        font-size: 3rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 2rem;
    }

    .about-media * {
        padding: 2rem .3rem 0 0;
        font-size: 1.4rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.8rem;
    }

    .about-skills-column li {
        font-size: 1.6rem;
        padding: 2rem .3rem;
    }
}

@media(max-width:375px) {
    .about-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 5rem;
    }

    .about-title2 {
        font-size: 5rem;
    }

    .about-subtitle {
        font-size: 3rem;
    }

    .about-text {
        padding: 4rem 0;
        font-size: 2rem;
    }

    .about-media * {
        padding: 2rem .4rem 0 0;
        font-size: 1.2rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.6rem;
    }

    .about-skills-column li {
        font-size: 1.4rem;
        padding: 2rem .3rem;
    }
}

@media(max-width:320px) {
    .about-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0rem 4rem;
    }

    .about-section {
        height: 100%;
        width: 100%;
    }

    .about-title1 {
        font-size: 4rem;
    }

    .about-title2 {
        font-size: 4rem;
    }

    .about-subtitle {
        font-size: 2.2rem;
    }

    .about-text {
        padding: 3rem 0;
        font-size: 1.8rem;
    }

    .about-media * {
        padding: 1rem .2rem 0 0;
        font-size: 1.1rem;
    }

    .about-skills-title {
        margin: 0 0 2rem 0;
        font-size: 1.8rem;
    }

    .about-skills-column li {
        font-size: 1.2rem;
        padding: 2rem .2rem;
    }
}
.email-about{
    transition: 1s ease-out;
}
.email-about:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transition: 1s ease-out;
}
.loader-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.loader-h1{
    font-size: 3rem;
    padding: .7rem;
    letter-spacing: .9rem;
}
.loader-h2{
    font-size: 2rem;
    letter-spacing: 1rem;
}
.loader-absolute{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
    z-index: 1;
}
/* Animation */
.fade-in-homeloader-title{
    animation: fade-in-homeloader-title .7s ease-in-out .7s both;
    -webkit-animation:fade-in-homeloader-title .7s ease-in-out .7s both;
}
@keyframes fade-in-homeloader-title {
    0% {opacity:0;}
    100% {opacity:1;}
}
@-webkit-keyframes fade-in-homeloader-title {
    0% {opacity:0;}
    100% {opacity:1;}
}
/* Media Queries */
@media(min-width:1600px){
    .loader-h1{
        font-size: 5rem!important;
        padding: 1rem!important;
        letter-spacing: 1.4rem!important;
    }
    .loader-h2{
        font-size: 3rem!important;
        letter-spacing: 2rem!important;
    }
}
@media (max-width:1440px){
    .loader-h1{
        font-size: 3rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.8rem;
        letter-spacing: .9rem;
    }
}
@media (max-width:1024px){
    .loader-h1{
        font-size: 3rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.8rem;
        letter-spacing: .9rem;
    }
}
@media (max-width:768px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .7rem;
    }
}
@media (max-width:425px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:375px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:320px){
    .loader-h1{
        font-size: 2rem;
        padding: 1rem;
    }
    .loader-h2{
        font-size: 1.3rem;
        letter-spacing: .5rem;
    }
}

.navbar-error{
    display: -webkit-flex;
    display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
	-webkit-align-items: center;
	        align-items: center;
	padding: 2rem 4rem;
	width: 100%;
    z-index: 3;
}
.nav-error-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: center;
	-webkit-justify-content: center;
	        justify-content: center;
}
.nav-error-link{
    font-size: 1.5rem;
	margin-left: 5rem;
    letter-spacing: .18rem;
	transition: 0.7s;
}
.nav-error-link:hover{
    color: rgb(245, 245, 245);
    cursor: pointer;
	transition: 0.5s;
	-webkit-transform: scale(1.02);
	        transform: scale(1.02);
	text-decoration: underline;
}
.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}
@media(min-width:1600px){
    .navbar-error{
		padding: 4rem 4rem!important;
	}
	.nav-error-link{
		font-size: 3rem!important;
	}
}
@media (max-width:768px){
	.navbar-error {
		position: relative;
	}
	.nav-error-links {
        position: absolute;
		display: -webkit-flex;
		display: flex;
		-webkit-flex-direction: column;
		        flex-direction: column;
		-webkit-justify-content: center;
		        justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
	}
	.nav-error-links.active {
        background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}
	.nav-error-item {
		padding: .5rem;
        border-bottom: 1px solid rgb(167, 167, 167);
	}
	.nav-icon {
		display: -webkit-flex;
		display: flex;
	}
}
.error-wrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.error-h1{
    font-size: 3rem;
    padding: .7rem;
    letter-spacing: 1rem;
}
.error-h2{
    font-size: 2rem;
    font-size: 2rem;
    letter-spacing: 1.4rem;
}
.error-absolute{
    position: absolute;
    top:0;
    right:0;
    bottom: 0;
    left: 0;
}
/* Media Queries */
@media(min-width:1600px){
    .error-h1{
        font-size: 6rem!important;
        padding: 3rem!important;
      }
    .error-h2{
        font-size: 4rem!important;
        letter-spacing: .5rem!important;
    }
}
@media(max-width:1440px){
    .error-h1{
        font-size: 3rem;
        padding: 2rem;
      }
    .error-h2{
        font-size: 2rem;
        letter-spacing: .5rem;
    }
}
@media(max-width:1024px){
    .error-h1{
        font-size: 3rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 3rem;
        letter-spacing: .5rem;
    }
}
@media (max-width:768px){
    .error-h1{
        font-size: 3rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 2rem;
        letter-spacing: .4rem;
    }
}
@media (max-width:425px){
    .error-h1{
        font-size: 2.5rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.5rem;
        letter-spacing: .4rem;
    }
}
@media (max-width:375px){
    .error-h1{
        font-size: 2rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.3rem;
        letter-spacing: .3rem;
    }
}
@media (max-width:320px){
    .error-h1{
        font-size: 2rem;
        padding: 1rem;
      }
    .error-h2{
        font-size: 1.2rem;
        letter-spacing: .3rem;
    }
}
