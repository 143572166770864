.main-footer{
    width: 100%;
    display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 4rem;
    opacity: 0;
	visibility: hidden;
	transform: translateY(-7rem);
}
.available-message{
    font-size: 2rem;
    text-align: start;
}
.social-media *{
    display: inline-block;
    align-self: center;
    justify-content: center;
    font-size: 1.5rem;
}
.github-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.github-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transform: scale(1.1);
    transition: 1s ease-out;
}
.behance-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.behance-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transform: scale(1.1);
    transition: 1s ease-out;
}
.linkedin-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;

}
.linkedin-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transform: scale(1.1);
    transition: 1s ease-out;
}
.email-media{
    transition: 1s ease-out;
    margin-left: 1.5rem;
}
.email-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transform: scale(1.1);
    transition: 1s ease-out;
}
.resume-media{
    transition: 1s ease-out;
}
.resume-media:hover{
    cursor: pointer;
    color: rgb(243, 243, 243);
    transform: scale(1.1);
    transition: 1s ease-out;
}
@media(min-width:1600px){
    .main-footer{
        padding: 0rem 4rem 4rem 4rem!important;
    }
    .available-message{
        font-size: 4rem;
    }
    .social-media *{
        font-size: 2.8rem;
        margin-left: 1.7rem;
    }
}
@media(max-width:1440px){
    .main-footer{
        padding: 2rem 4rem 0rem 4rem;
    }
    .available-message{
        font-size: 2rem;
    }
    .social-media *{
        font-size: 1.4rem;
    }
}
@media(max-width:1024px){
    .main-footer{
        padding: 4rem 4rem;
    }
    .available-message{
        font-size: 1.8rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:768px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.8rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:425px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.4rem;
    }
    .social-media *{
        font-size: 1.2rem;
    }
}
@media(max-width:375px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.3rem;
    }
    .social-media *{
        font-size: 1rem;
    }
}
@media(max-width:320px){
    .main-footer{
        padding: 2rem 4rem;
    }
    .available-message{
        font-size: 1.3rem;
    }
    .social-media *{
        font-size: .9rem;
    }
}