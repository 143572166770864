.navbar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3rem 4rem;
	width: 100%;
}

.navbar-name {
	font-size: 2.5rem;
	letter-spacing: .3rem;
	text-transform: uppercase;
	font-weight: bold;
	justify-self: start;
	display: flex;
	align-items: center;
	transition: 0.7s;
	font-weight: 800;
	opacity: 0;
	visibility: hidden;
	transform: translateY(+7rem);
	color: rgb(207, 207, 207);
}

.nav-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: left;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transform: translateY(+7rem);
}

.nav-link {
	font-size: 1.5rem;
	margin-left: 5rem;
	letter-spacing: .18rem;
	transition: 0.7s;
}

.nav-link:hover {
	color: rgb(245, 245, 245);
	cursor: pointer;
	transition: 0.5s;
	transform: scale(1.02);
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media(min-width:1600px) {
	.navbar {
		padding: 4rem 4rem !important;
	}

	.navbar-name {
		font-size: 6rem !important;
	}

	.nav-link {
		font-size: 3rem !important;
	}
}

@media(min-width:1440px) {
	.navbar-name {
		font-size: 2.5rem;
	}

	.nav-link {
		font-size: 1.8rem;
	}
}

@media (max-width:768px) {
	.navbar {
		position: relative;
	}

	.nav-links {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
		font-size: 1.2rem;
	}

	.nav-links.active {
		background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}

	.nav-item {
		padding: .5rem;
		border-bottom: 1px solid rgb(167, 167, 167);
	}

	.nav-icon {
		display: flex;
	}
}

@media(max-width:425px) {
	.navbar {
		padding: 2.5rem 4rem;
	}
}

@media(max-width:375px) {
	.navbar {
		padding: 2.5rem 4rem;
	}

	.navbar-name {
		font-size: 2.1rem;
	}

	.nav-item {
		padding: .1rem;
	}
}

@media(max-width:320px) {
	.navbar {
		padding: 2.5rem 4rem;
	}

	.navbar-name {
		font-size: 2.1rem;
	}

	.nav-item {
		padding: .1rem;
	}
}