.navbar-about {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 3.5rem 4rem 0rem 4rem;
	width: 100%;
	z-index: 3;

}

.nav-about-links {
	display: grid;
	grid-template-columns: repeat(4, auto);
	text-align: center;
	justify-content: center;
}

.nav-about-link {
	font-size: 1.5rem;
	margin-left: 5rem;
	letter-spacing: .18rem;
	transition: 0.7s;
}

.nav-about-link:hover {
	color: rgb(245, 245, 245);
	cursor: pointer;
	transition: 0.5s;
	transform: scale(1.02);
}

.nav-icon {
	display: none;
	font-size: 2rem;
	cursor: pointer;
}

@media(min-width:1600px) {
	.navbar-about {
		padding: 5.3rem 4rem 0rem 4rem !important;
	}

	.nav-about-link {
		font-size: 3rem !important;
	}
}

@media(min-width:1440px) {
	.nav-about-link {
		font-size: 1.8rem;
	}
}

@media (max-width:768px) {
	.navbar-about {
		position: relative;
		top: 0rem;
		z-index: 33
	}

	.nav-about-links {
		position: absolute;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		top: 5rem;
		left: -100%;
		transition: 0.6s all;
	}

	.nav-about-links.active {
		background-image: linear-gradient(to bottom, #dadada, rgb(175, 175, 175));
		left: 0;
	}

	.nav-about-item {
		padding: .1rem;
		border-bottom: 1px solid rgb(167, 167, 167);
	}

	.nav-icon {
		display: flex;
		top: -1rem;
	}
}

@media(min-width:375px) {
	.nav-about-links {
		top: 3.5rem;
	}
}

@media(min-width:320px) {
	.nav-about-links {
		top: 3.6rem;
	}
}